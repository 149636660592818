<template>
  <el-dialog
    title="移仓"
    :close-on-click-modal="false"
    append-to-body
    :visible.sync="visible"
    class="missionPart"
  >
    <div class="autoBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="
            item.index === 0 ? '1号架' : item.index === 1 ? '2号架' : '3号架'
          "
          :name="
            item.index === 0 ? '1号架' : item.index === 1 ? '2号架' : '3号架'
          "
          v-for="item in tableData"
          :key="item.index"
        >
          <!-- 一个货架 -->
          <div
            class="inlineBox"
            v-for="(sitem, sindex) in item.shelves"
            :key="sitem.index"
          >
            <!-- 货架标题 -->
            <div class="boxTil">
              {{
                sindex === 0
                  ? '上层货架'
                  : sindex === 1
                  ? '中层货架'
                  : '下层货架'
              }}
            </div>
            <!-- 货架每行 -->
            <div
              class="boxList"
              v-for="(kitem, kindex) in sitem.rows"
              :key="kindex"
            >
              <!-- 货架每列 -->
              <div
                class="boxItem"
                v-for="(litem, lindex) in kitem.cols"
                :key="lindex"
                @click="checkCode(litem)"
                :class="[
                  { darkCode: litem.state === 1 || litem.state === 2 },
                  { chooseCheck: litem.spaceCode === activeCode },
                ]"
              >
                <p>{{ litem.spaceCode }}</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import { normal } from '@/mixins';
export default {
  mixins: [normal],
  data() {
    return {
      visible: false,
      activeName: '1号架', // 智能取餐柜货架1号架 2号架 以此类推
      activeCode: 0,
      dataForm: {
        orderDetailId: 0,
        spaceCode: 0,
        state: 0,
      },
      chooseDate: null, // 默认拿当天日期，用于通过日期查询列表的数据
      tableData: [], // 智能取餐柜的层数列
      dataList: [], // 右侧表格显示的内容页，默认和上面的存储数据一样
    };
  },
  methods: {
    init(row, rowdata, orderDetailId) {
      this.tableData = row;
      this.dataList = rowdata;
      this.dataForm.orderDetailId = orderDetailId;
      this.visible = true;
    },
    // 货架切换
    handleClick(tab) {
      this.activeName = tab.name;
    },
    // 点击触发货架仓位内容查询
    checkCode(row) {
      if (row.goodsNum === 0) {
        this.dataForm.spaceCode = row.spaceCode;
        this.dataForm.state = row.state;
        this.activeCode = row.spaceCode;
      } else {
        this.$message.error('当前仓位已经分配，无法选择');
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$http({
        url: `/tc/orderDetail/move`,
        method: 'post',
        params: {
          orderDetailId: this.dataForm.orderDetailId,
          spaceCode: this.dataForm.spaceCode,
        },
        data: {
          goodsNum: 0,
          index: 1,
          spaceCode: this.dataForm.spaceCode,
          state: 0,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.$message({
            message: '移仓成功',
            type: 'success',
            duration: 1500,
          });
          this.visible = false;
          this.$emit('refreshDataList');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.autoBox {
  position: relative;
  .el-tabs {
    position: relative;
    .el-tabs__content {
      .el-tab-pane {
        position: relative;
        //border: 1px #efefef solid;
        .inlineBox {
          position: relative;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          .boxTil {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            padding: 5px;
            flex: 0 0 100%;
            margin: 15px 0 0;
            border: 1px #efefef solid;
          }
          .boxList {
            flex: 0 0 100%;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            .boxItem {
              flex: 1;
              margin: 8px 8px 0 0;
              border: 1px #efefef solid;
              font-size: 13px;
              color: #666;
              text-align: center;
              padding: 4px 0;
              cursor: pointer;
            }
            .darkCode {
              background-color: #999;
              cursor: not-allowed;
              color: #fff;
            }
            .chooseCheck {
              background-color: #409eff;
              color: #fff;
            }
            .activeBtn {
              background-color: #0bb2d4;
              color: #000;
              border-color: #0bb2d4;
            }
          }
          &:first-child {
            .boxTil {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
